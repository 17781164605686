export default [
  {
    type: 'jimu',
    title: '积幕',
    show: '堆积无穷灵感',
    dec1: '创作者一站式资源共享平台',
    dec2: '集视频内容分享、服务交易、人才招聘、互动交流的数字创作灵感社区',
    more:'更多详情：jimu.movtile.com',
    button: 'APP下载',
    icon: 'android',
    download:'https://jimu.movtile.com/index-web.html',
  },
  {
    type: 'youke',
    title: '幕童有课',
    show: '数字全能公开课',
    dec1: '专注视频创作领域的在线学习平台',
    dec2: '引进国内外雄厚师资，精选高质量实用课程，全面满足各阶段人士需要',
    more: '更多详情：ke.movtile.com',
    button: 'APP下载',
    icon: 'android',
    download:'https://jimu.movtile.com/index-web.html',
  },
  {
    type: 'yunyue',
    title: '幕童云阅',
    show: '视频云端协作工具',
    dec1: '真正实现在线协作的视频管理工具',
    dec2: '支持大体量视频素材上传共享，轻松完成在线协同剪辑，随时随地阅览、批注、下载。',
    more:'更多详情：io.movtile.com',
    button: 'iOS下载',
    icon: 'ios',
    download:'https://apps.apple.com/cn/app/id1529390335'
  },
  {
    type: 'changji',
    title: '幕童场记',
    show: '拍摄数据速记专家',
    dec1: '为场记/特效人员提供的快速记录工具',
    dec2: '可预设常用数据，快速记录，支持图片绘制，记录更精准！断网也能用，数据同步更新。',
    more: '更多详情：apps.apple.com/cn/app/id1561696716',
    button: 'iPad下载',
    icon: 'ios',
    download:'https://apps.apple.com/cn/app/id1561696716'
  },
];
