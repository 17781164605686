<template>
  <div class="tool">
    <span v-for="(item, index) in fixedData" :key="index">
      <img
        :src="`https://mutong-ketang-img.oss-cn-shanghai.aliyuncs.com/res/official/fixed-logo-${item.type}.png`"
        alt=""
        @click="swiperTo(index + 1)"
        :class="{ active: index === _myIndex - 1, toTop: index === 4 }"
      />
    </span>
  </div>
</template>
<script>
  import indexProduct from '@/data/indexProduct';

  export default {
    props: ['swiper', 'myIndex'],
    data() {
      return {
        fixedData: JSON.parse(JSON.stringify(indexProduct)),
      };
    },
    computed: {
      _myIndex() {
        // 直接监听props里的myIndex
        return this.myIndex;
      },
    },
    // watch: {
    //   _myIndex(val) {
    //     console.log(val);
    //   },
    // },
    methods: {
      // 导航去到不同的页面
      swiperTo(val) {
        val === 5 ? this.swiper.slideTo(0, 500, true) : this.swiper.slideTo(val, 500, true);
      },
    },
    beforeMount() {
      // 改变一下数据
      this.fixedData.push({
        type: 'toTop',
      });
    },
    mounted() {},
  };
</script>
<style lang="less" scoped>
  .tool {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 60px;
    z-index: 100;
    transition: all ease 0.3s;
    span {
      position: relative;
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-top: 10px;
      img {
        position: absolute;
        right: 0;
        top: 0;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: block;
        background-color: #fff;
        box-shadow: 0px 10px 18px 5px rgba(0, 0, 0, 0.1);
        transition: all 0.4s ease;
        cursor: pointer;
        &:hover {
          width: 100px;
          height: 100px;
          right: -10px;
          top: -10px;
        }
      }
      .active {
        width: 100px;
        height: 100px;
        right: -10px;
        top: -10px;
      }
      .toTop {
      }
    }
  }
  @media screen and (max-width: 1536px) {
    .tool {
      right: 30px;
      span {
        width: 70px;
        height: 70px;
        img {
          width: 60px;
          height: 60px;
          &:hover {
            width: 70px;
            height: 70px;
            right: -5px;
            top: -5px;
          }
        }
        .active {
          width: 70px;
          height: 70px;
          right: -5px;
          top: -5px;
        }
      }
    }
  }
  @media screen and (max-width: 1280px) {
  }

  @media screen and (max-width: 768px) {
    .tool {
      right: 10px;
      span {
        width: 40px;
        height: 40px;
        margin-top: 5px;
        img {
          width: 32px;
          height: 32px;
          &:hover {
            width: 40px;
            height: 40px;
            right: -4px;
            top: -4px;
          }
        }
        .active {
          width: 40px;
          height: 40px;
          right: -4px;
          top: -4px;
        }
      }
    }
  }
</style>
