var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('Header',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isShow),expression:"!isShow"}]}),_c('swiper',{ref:"mySwiper",staticClass:"swiper-container",attrs:{"options":_vm.swiperOption}},[_c('swiper-slide',{staticClass:"home-bg",style:({
        backgroundRepeat: 'no-repeat',
        backgroundPosition: !_vm.isPc ? '64% 0px' : '',
      })},[_c('div',{staticClass:"an",attrs:{"id":"parallax-scene"}},[_c('div',{staticClass:"img",attrs:{"data-depth":"0.2"}},[_c('img',{attrs:{"src":"https://mutong-ketang-img.oss-cn-shanghai.aliyuncs.com/res/official/index-top-1.png","alt":""}})]),_c('div',{staticClass:"img2",attrs:{"data-depth":"0.3"}},[_c('img',{attrs:{"src":"https://mutong-ketang-img.oss-cn-shanghai.aliyuncs.com/res/official/index-top-2.png","alt":""}})])]),_c('div',{staticClass:"home-message"},[_c('h1',[_vm._v("THIS IS MOVTILE WORLD")]),_c('p',[_vm._v("幕童")]),_c('div',[_vm._v("全能视频创作“梦工厂”")])]),_c('div',{staticClass:"glide",on:{"click":_vm.next}},[_c('p',[_vm._v("幕童系列产品")]),_c('img',{staticClass:"animate__animated animate__slideInDown animate__infinite infinite",attrs:{"src":require("@/assets/images/down.png"),"alt":""}})])]),_vm._l((_vm.showData),function(item,index){return _c('swiper-slide',{key:index,staticClass:"slide-item",style:({
        backgroundImage: ("url(https://mutong-ketang-img.oss-cn-shanghai.aliyuncs.com/res/official/index-" + (item.type) + "-bg.jpg)"),
        backgroundRepeat: 'no-repeat',
        backgroundPosition:
          !_vm.isPc && index % 2 ? '62% 0px' : !_vm.isPc && !(index % 2) ? '96% 0px' : '',
      })},[_c('img',{staticClass:"imgss",class:index === _vm.myIndex - 1 ? 'animate__animated animate__fadeInUp slide-item-img imgs' : '',style:({ left: index % 2 && _vm.isPc ? '45%' : index % 2 && !_vm.isPc ? '6%' : !(index % 2) && !_vm.isPc ? '28%' : '' ,}),attrs:{"src":("https://mutong-ketang-img.oss-cn-shanghai.aliyuncs.com/res/official/index-img-" + (item.type) + ".png")}}),_c('div',{staticClass:"imgss",class:index === _vm.myIndex - 1 ? 'animate__animated animate__fadeInDown detaile' : ''},[_c('img',{staticClass:"logo",attrs:{"src":("https://mutong-ketang-img.oss-cn-shanghai.aliyuncs.com/res/official/index-logo-" + (item.type) + ".png"),"alt":""}}),_c('span',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"show"},[_vm._v(_vm._s(item.show))]),_c('p',{staticClass:"dec"},[_vm._v(_vm._s(item.dec1))]),_c('p',{staticClass:"dec"},[_vm._v(_vm._s(item.dec2))]),_c('a',{staticClass:"more",attrs:{"target":"blank","href":'https://' + item.more.slice(5)}},[_vm._v(_vm._s(item.more))]),(item.button)?_c('div',{staticClass:"button",on:{"click":function($event){return _vm.toDownload(item.download)}}},[(item.icon)?_c('img',{staticClass:"icon-img",attrs:{"src":("https://mutong-ketang-img.oss-cn-shanghai.aliyuncs.com/res/official/" + (item.icon) + ".png"),"alt":""}}):_vm._e(),_vm._v(" "+_vm._s(item.button)+" "),_c('img',{staticClass:"ma-img",style:(_vm.isPc ? '' : 'display:none;'),attrs:{"src":("https://mutong-ketang-img.oss-cn-shanghai.aliyuncs.com/res/official/ma-" + (item.type) + ".png"),"alt":""}})]):_vm._e()]),(item.type === 'changji')?_c('span',{staticClass:"footer"},[_c('span',[_vm._v("Copyright © 2010 - 2021 movtile.com All Rights Reserved.")]),_c('span',[_vm._v("京公网安备 11010502041446号 京ICP备19053590号-2")])]):_vm._e()])})],2),(_vm.isShow)?_c('Tool',{attrs:{"swiper":_vm.swiper,"myIndex":_vm.myIndex}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }