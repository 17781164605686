<template>
  <div class="home">
    <!-- 轮播图 -->
    <Header v-show="!isShow"></Header>
    <swiper :options="swiperOption" class="swiper-container" ref="mySwiper">
      <!-- 首屏展示图 -->
      <swiper-slide
        class="home-bg"
        :style="{
          backgroundRepeat: 'no-repeat',
          backgroundPosition: !isPc ? '64% 0px' : '',
        }"
      >
        <!-- 深景效果 -->
        <div id="parallax-scene" class="an">
          <div class="img" data-depth="0.2">
            <img
              src="https://mutong-ketang-img.oss-cn-shanghai.aliyuncs.com/res/official/index-top-1.png"
              alt=""
            />
          </div>
          <div class="img2" data-depth="0.3">
            <img
              src="https://mutong-ketang-img.oss-cn-shanghai.aliyuncs.com/res/official/index-top-2.png"
              alt=""
            />
          </div>
        </div>
        <!-- title -->
        <div class="home-message">
          <h1>THIS IS MOVTILE WORLD</h1>
          <p>幕童</p>
          <div>全能视频创作“梦工厂”</div>
        </div>
        <!-- 下拉 -->
        <div class="glide" @click="next">
          <p>幕童系列产品</p>
          <img
            class="animate__animated animate__slideInDown animate__infinite infinite"
            src="@/assets/images/down.png"
            alt=""
          />
        </div>
      </swiper-slide>
      <!-- 其他模块 -->
      <swiper-slide
        class="slide-item"
        v-for="(item, index) in showData"
        :key="index"
        :style="{
          backgroundImage: `url(https://mutong-ketang-img.oss-cn-shanghai.aliyuncs.com/res/official/index-${item.type}-bg.jpg)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition:
            !isPc && index % 2 ? '62% 0px' : !isPc && !(index % 2) ? '96% 0px' : '',
        }"
      >
        <img
          :src="`https://mutong-ketang-img.oss-cn-shanghai.aliyuncs.com/res/official/index-img-${item.type}.png`"
          :class="
            index === myIndex - 1 ? 'animate__animated animate__fadeInUp slide-item-img imgs' : ''
          "
          :style="{ left: index % 2 && isPc ? '45%' : index % 2 && !isPc ? '6%' : !(index % 2) && !isPc ? '28%' : '' ,}"
          class="imgss"
        />
        <!-- 左边的文案加logo -->
        <div
          :class="index === myIndex - 1 ? 'animate__animated animate__fadeInDown detaile' : ''"
          class="imgss"
        >
          <img
            :src="`https://mutong-ketang-img.oss-cn-shanghai.aliyuncs.com/res/official/index-logo-${item.type}.png`"
            class="logo"
            alt=""
          />
          <span class="title">{{ item.title }}</span>
          <span class="show">{{ item.show }}</span>
          <p class="dec">{{ item.dec1 }}</p>
          <p class="dec">{{ item.dec2 }}</p>
          <a class="more" target="blank" :href="'https://' + item.more.slice(5)">{{ item.more }}</a>
          <div v-if="item.button" class="button" @click="toDownload(item.download)">
            <img
              v-if="item.icon"
              class="icon-img"
              :src="`https://mutong-ketang-img.oss-cn-shanghai.aliyuncs.com/res/official/${item.icon}.png`"
              alt=""
            />
            {{ item.button }}
            <img
              class="ma-img"
              :src="`https://mutong-ketang-img.oss-cn-shanghai.aliyuncs.com/res/official/ma-${item.type}.png`"
              alt=""
              :style="isPc ? '' : 'display:none;'"
            />
          </div>
        </div>
        <span class="footer" v-if="item.type === 'changji'">
          <span>Copyright © 2010 - 2021 movtile.com All Rights Reserved.</span>
          <span>京公网安备 11010502041446号 京ICP备19053590号-2</span>
        </span>
      </swiper-slide>
    </swiper>

    <!-- 工具栏 -->
    <Tool v-if="isShow" :swiper="swiper" :myIndex="myIndex"></Tool>
  </div>
</template>

<script>
  // swiper
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  import 'swiper/css/swiper.min.css';

  import Parallax from 'parallax-js';

  import indexProduct from '@/data/indexProduct';

  import Header from '@/components/Header';
  import Tool from '@/components/Tool';
  export default {
    name: 'Home',
    components: {
      Header,
      Tool,
      Swiper,
      SwiperSlide,
    },

    data() {
      // 记录this
      const that = this;
      return {
        // 是不是ios
        isIphone: false,
        // 是不是pc
        isPc: true,
        // 是否展示工具栏
        isShow: false,
        // 记录播放到第几页
        myIndex: '',
        // 数据源
        showData: indexProduct,
        // swiper配置
        swiperOption: {
          direction: 'vertical', // Slides的滑动方向-垂直
          speed: 300, // 切换速度
          mousewheel: true, // 开启鼠标滚轮控制Swiper切换。
          shortSwipes: true, //默认允许短切换。设置为false时，只能长切换，进行快速且短距离的滑动无法触发切换。
          // longSwipes: true, //设置为false时，只能长切换，进行快速且短距离的滑动无法触发切换。
          // longSwipesMs: 100, //定义longSwipes的时间（单位ms），超过则属于longSwipes。
          // longSwipesRatio: 0.2, //进行longSwipes时触发swiper所需要的最小拖动距离比例，即定义longSwipes距离比例。值越大触发Swiper所需距离越大。最大值0.5。

          on: {
            slideChangeTransitionEnd: function () {
              that.myIndex = this.realIndex;
            },
            slideChangeTransitionStart: function () {
              // 如果是到首页,那就先隐藏
              if (this.realIndex === 0) {
                that.isShow = false;
              }
            },
          },
        },
      };
    },
    watch: {
      myIndex(val) {
        this.isShow = val > 0 ? true : false;
      },
    },
    computed: {
      // 定义swiper，在下面就可以引用这个swiper了；
      swiper() {
        return this.$refs.mySwiper.$swiper;
      },
    },
    methods: {
      // 初始化深景运动
      init() {
        let scene = document.getElementById('parallax-scene');
        let parallaxInstance = new Parallax(scene);
      },
      // 下一页
      next() {
        // 第三个参数设置为false时不会触发transition回调函数
        this.swiper.slideTo(1, 500, true);
      },
      // 去下载
      toDownload(val) {
        val && window.open(val);
      },
      // 判断是不是pc
      isPC() {
        var userAgentInfo = navigator.userAgent;
        var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
          if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
            break;
          }
        }
        return flag;
      },
    },
    created() {
      // 判断是pc还是移动端
      this.isPc = this.isPC();
    },
    beforeMount() {
      // 是ios改变配置
      // var agent = navigator.userAgent.toLowerCase();
      // safari浏览器自带的工具栏会遮挡，把工具栏隐藏就没问题
      // /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
      // this.isIphone = agent.indexOf('iphone') > -1 || agent.indexOf('iPad') > -1;
      // 不是pc端就是改变配置
      if (this.isPc) {
        this.swiperOption.longSwipes = true;
        this.swiperOption.longSwipesMs = 100;
        this.swiperOption.longSwipesRatio = 0.2;
      }
    },
    mounted() {
      // 初始化深景运动
      this.init();
    },
  };
</script>
<style lang="less" scoped>
  // 一开始的动画
  .an {
    animation: home_animate 7s infinite alternate cubic-bezier(0.39, -0.02, 0.59, 0.99);
  }
  @keyframes home_animate {
    0% {
      transform: translate(0px, 0px);
    }
    100% {
      transform: translate(50px, -50px);
    }
  }
  .home {
    .swiper-container {
      height: 100vh;
      width: 100vw;
      .home-bg {
        position: relative;
        background: url(https://mutong-ketang-img.oss-cn-shanghai.aliyuncs.com/res/official/index-top-bg.jpg)
          no-repeat;
        background-size: cover;
        overflow: hidden;

        .img {
          height: 100%;
          width: 50%;
        }
        .img2 {
          height: 100%;
          width: 65%;
          margin-left: 40%;
          margin-top: -2%;
        }
        .home-message {
          position: absolute;
          top: 30%;
          left: 50%;
          transform: translateX(-50%);
          color: #fff;
          font-size: 20px;
          h1 {
            font-family: Knockout;
            font-size: 90px;
            white-space: nowrap;
            background-image: -webkit-linear-gradient(left, #fff5df, #b8e1ff, #fff5df);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          p {
            margin-top: 102px;
          }
          div {
            margin-top: 10px;
          }
        }
        .glide {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
          cursor: pointer;
          p {
            font-size: 14px;
            margin-bottom: 30px;
            color: #fff;
          }
          img {
            height: 33px;
            width: 22px;
            display: inline-block;
            margin-top: 5px;
            animation-duration: 1.6s;
            animation-direction: alternate;
          }
        }
      }
      .slide-item {
        position: relative;
        overflow: hidden;
        background-size: cover;
        .slide-item-img {
          position: absolute;
        }
        .imgs {
          height: 100%;
          bottom: 0;
          left: 48%;
          max-width: none;
          padding-top: 9%;
          animation-duration: 1.6s;
          opacity: 1;
          z-index: 98;
        }
        // 一开始背景图的动画
        .imgss {
          opacity: 0;
        }
        .detaile {
          position: absolute;
          width: 640px;
          top: 22%;
          left: 10%;
          text-align: left;
          color: #111111;
          animation-duration: 1.6s;
          opacity: 1;
          z-index: 99;
          .logo {
            display: block;
            width: 120px;
            height: 120px;
            margin-bottom: 60px;
          }
          .title {
            font-weight: 600;
            font-size: 60px;
          }
          .show {
            margin-left: 30px;
            font-weight: 800;
            font-size: 20px;
          }
          .dec {
            margin-top: 15px;
            font-size: 16px;
            font-weight: 400;
          }
          .more {
            position: relative;
            display: inline-block;
            margin-top: 30px;
            font-size: 14px;
            font-weight: 800;
            color: #111111;
            &::after {
              content: '';
              position: absolute;
              top: 7px;
              right: -18px;
              height: 10px;
              width: 10px;
              background: url(../assets/images/url-go.png) no-repeat;
              background-size: cover;
            }
          }
          .button {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 70px;
            width: 140px;
            height: 45px;
            border-radius: 5px;
            font-size: 14px;
            background-color: #eee;
            cursor: pointer;
            &:hover .ma-img {
              opacity: 1;
              visibility: visible;
            }
            .icon-img {
              display: inline-block;
              width: 17px;
              height: 17px;
              border-radius: 2px;
              margin-right: 5px;
              margin-top: -3px;
            }
            .ma-img {
              position: absolute;
              top: -150px;
              width: 140px;
              height: 140px;
              background-color: #fff;
              opacity: 0;
              visibility: hidden;
              padding: 10px;
              box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.3);
            }
          }
        }
        .footer {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 12px;
          font-weight: 400;
          color: #000000;
          opacity: 0.5;
          
        }
      }
    }
  }
  // 适配
  @media screen and (max-width: 1536px) {
    .home {
      .swiper-container {
        .home-bg {
          .home-message {
            h1 {
              font-size: 60px;
            }
            p {
              margin-top: 45px;
            }
          }
        }
        .slide-item {
          .detaile {
            .logo {
              width: 80px;
              height: 80px;
              margin-bottom: 30px;
            }
            .title {
              font-size: 40px;
            }
            .show {
              font-size: 15px;
            }
            .dec {
              font-size: 13px;
            }
            .button {
              margin-top: 40px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1280px) {
    .home {
      .swiper-container {
        .home-bg {
          .home-message {
            h1 {
              font-size: 40px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .home {
      .swiper-container {
        .home-bg {
          #parallax-scene {
            padding-top: 85%;
            .img{
              width: 80%;
              margin-top: -90%;
              margin-left: -10%;
            }
            .img2 {
              width: 120%;
              margin-top: 85%;
              margin-left: -13%;
            }
          }
          .home-message {
            top: 20%;
            h1 {
              font-size: 20px;
            }
            p,
            div {
              font-size: 14px;
            }
          }
          .glide {
            bottom: 90px;
          }
        }
        .slide-item {
          .imgs {
            height: 60%;
            // height: initial;
            // width: 70%;
          }
          .detaile {
            top: 7%;
            .logo {
              width: 60px;
              height: 60px;
            }
            .title {
              margin-top: 20px;
              font-size: 30px;
            }
            .show {
              font-size: 10px;
            }
            .dec {
              font-size: 10px;
              width: 250px;
              white-space: pre-wrap;
            }
            .more {
              margin-top: 20px;
              width: 250px;
              font-size: 12px;
              &::after {
                display: none;
              }
            }
            .button{
              width: 100px;
              height: 30px;
              margin-top: 20px;
              font-size: 12px;
              .icon-img {
                display: inline-block;
                width: 12px;
                height: 12px;
                margin-right: 5px;
                margin-top: 0px;
              }
            }
          }
          .footer {
            font-size: 10px;
            span{
              display: block;
              white-space: nowrap;
            }      
          }
        }
      }
    }
  }
</style>
